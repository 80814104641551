var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "el-dialog-job",
      attrs: {
        width: "98%",
        title: _vm.isUnlink ? "Unlink Jobs" : "Link Jobs",
        visible: _vm.dialogLinkJobs,
      },
      on: {
        close: _vm.handleLinkJobsDialogClose,
        open: _vm.handleLinkJobsDialogOpen,
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "div-job",
        },
        [
          _c("table-job", {
            attrs: {
              hasCheckbox: "",
              isJobTableLoading: _vm.isJobTableLoading,
              jobTableData: _vm.jobTableData,
              jobPagination: _vm.jobPagination,
              hasPagination: "",
              jobsSelected: _vm.jobsSelected,
              isViewOnly: "",
            },
            on: {
              handleJobPageChange: _vm.handleJobPageChange,
              handleJobSelectionChange: _vm.handleJobSelectionChange,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "el-dialog__footer" },
        [
          _c(
            "el-button",
            {
              attrs: { outline: "", round: "" },
              on: { click: _vm.handleLinkJobsDialogClose },
            },
            [_vm._v(" Cancel ")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                round: "",
                type: "primary",
                disabled: _vm.jobsSelected.length < 1,
              },
              on: { click: _vm.handleConfirmLinkJobsClick },
            },
            [_vm._v(" Confirm ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }