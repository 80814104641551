<template>
  <el-dialog
    class="el-dialog-job"
    width="98%"
    :title="isUnlink ? 'Unlink Jobs' : 'Link Jobs'"
    :visible="dialogLinkJobs"
    @close="handleLinkJobsDialogClose"
    @open="handleLinkJobsDialogOpen"
  >
    <div class="div-job" v-loading="loading">
      <table-job hasCheckbox :isJobTableLoading="isJobTableLoading" :jobTableData="jobTableData" :jobPagination="jobPagination" hasPagination
        @handleJobPageChange="handleJobPageChange" :jobsSelected="jobsSelected" @handleJobSelectionChange="handleJobSelectionChange" isViewOnly/>
    </div>
    <div class="el-dialog__footer">
      <el-button outline round @click="handleLinkJobsDialogClose">
        Cancel
      </el-button>
      <el-button round  type="primary" :disabled="jobsSelected.length < 1" @click="handleConfirmLinkJobsClick">
        Confirm
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import TableJob from '@/views/businessManager/operations/components/TableJob.vue'
import { ORDER_TYPE, TERM_CONTRACT_TYPE } from '@/constants.js'
export default {
  name: 'DialogLinkJobs',
  components: { TableJob },
  props: {
    dialogLinkJobs: Boolean,
    isUnlink: Boolean,
    orderId: String,
    order: Object
  },
  data () {
    const JOB_TYPES = {
      1: 'Delivery',
      2: 'Loading',
      3: 'STS'
    }
    const jobPagination = {
      pageSize: 50,
      pageNumber: 0,
      total: 0
    }
    return {
      JOB_TYPES,
      jobTableData: [],
      isJobTableLoading: false,
      jobPagination,
      jobsSelected: [],
      productDetails: [],
      loading: false
    }
  },
  methods: {
    handleLinkJobsDialogClose () {
      this.$emit('handleLinkJobsDialogClose')
    },
    handleLinkJobsDialogOpen () {
      this.jobTableData = []
      let query = null
      if (this.isUnlink) {
        query = `orderId:'${this.orderId}'`
      } else {
        const productSet = new Set()
        this.order.products.forEach(p => {
          productSet.add(`${p.sysProductId}~${p.sysProductSpecificationId}`)
        })
        let productQuery = ''
        let index = 0
        productSet.forEach(p => {
          if (index < 1) {
            productQuery = productQuery.concat(`'${p}'`)
          } else {
            productQuery = productQuery.concat(`,'${p}'`)
          }
          index++
        })
        let jobType = null
        if (this.order.orderType === ORDER_TYPE.DELIVERED || this.order.orderType === ORDER_TYPE.BARGING_NOMINATION) {
          jobType = 1
        } else {
          jobType = 2
        }
        query = `orderId is null and type:'${jobType}'${jobType === 1 ? ` and vesselImo:'${this.order.vesselImoNo}'` : ''} ${productQuery ? `and concat(sysProductId,'~',sysProductSpecificationId) in (${productQuery})` : ''}`
      }
      this.getJobTableData(50, 0, query)
    },
    handleJobPageChange (pageNumber) {
      this.getJobTableData(this.jobPagination.pageSize, pageNumber - 1)
    },
    handleJobPageSizeChange (pageSize) {
      this.getJobTableData(pageSize, 0)
    },
    handleJobSelectionChange (val) {
      this.jobsSelected = val
    },
    getJobTableData (pageSize, pageNumber, query = null) {
      this.isJobTableLoading = true
      let filter = null
      if (query) {
        filter = `&filter=${query}`
      }
      this.$request
        .get({
          url: `/ebdn/jobs/all?pageSize=${pageSize}&pageNumber=${pageNumber}${filter ?? ''}`
        }).then((res) => {
          if (res?.code === 1000) {
            this.jobTableData = res.data.content.map((item) =>
              Object.assign({}, item, {
                datetime:
                  item.type === 2
                    ? item.loadingTime
                      ? item.loadingTime
                      : ''
                    : item.vesselEta
                      ? item.vesselEta
                      : '',
                locationLevel: [item.locationLevel1, item.locationLevel2],
                terminal: item.type === 2 ? item.locationLevel1 : '',
                quantity:
                  (item.type === 2 ? item.loadingQty : item.finalQty) +
                  item.qtyUnitMetric,
                typeDisplay: this.generateJobTypeDisplay(item),
                orderDateDisplay: `${
                  item.stemStartDate
                    ? this.$moment(item.stemStartDate).format('DD-MM-YYYY')
                    : ''
                }\n${
                  item.stemEndDate
                    ? this.$moment(item.stemEndDate).format('DD-MM-YYYY')
                    : ''
                }`
              })
            )
            this.jobPagination.total = res.data.totalElements
            this.jobPagination.pageNumber = res.data.pageable.pageNumber + 1
          }
        }).finally(() => {
          this.isJobTableLoading = false
        })
    },
    generateJobTypeDisplay (job) {
      const withOrder = job.orderId !== null && job.orderId !== undefined
      const ownedJob = (this.currentCompany !== null && this.currentCompany !== undefined) &&
        ((this.currentCompany.id === job.mitigatingCompanyOrgId) || (this.currentCompany.id === job.sellerSysOrganizationId) || (this.currentCompany.id === job.buyerSysOrganizationId))

      if (withOrder && ownedJob) {
        if (job.orderType === ORDER_TYPE.BARGING_NOMINATION) {
          if (job.termContractType) {
            return job.termContractType === TERM_CONTRACT_TYPE.COA ? `${this.JOB_TYPES[job.type]} - B (COA)` : `${this.JOB_TYPES[job.type]} - B (TC)`
          } else {
            return `${this.JOB_TYPES[job.type]} - B`
          }
        } else {
          if (job.termContractType) {
            return `${this.JOB_TYPES[job.type]} - Term`
          } else {
            return this.JOB_TYPES[job.type]
          }
        }
      }
      return this.JOB_TYPES[job.type]
    },
    handleConfirmLinkJobsClick () {
      const jobIdArray = []
      this.jobsSelected.forEach(j => {
        jobIdArray.push(j.id)
      })
      this.loading = true
      if (!this.isUnlink) {
        this.handleLinkJobs(jobIdArray)
      } else {
        this.handleUnlinkJobs(jobIdArray)
      }
    },
    handleLinkJobs (jobIdArray) {
      this.$request.post({
        url: `${this.$apis.orders}/${this.orderId}/link_jobs`,
        data: jobIdArray
      }).then(res => {
        if (res?.code === 1000) {
          this.$message.success('Jobs successfully linked')
          this.handleLinkJobsDialogClose()
        }
      }).finally(() => {
        this.loading = false
      })
    },
    handleUnlinkJobs (jobIdArray) {
      this.$request.post({
        url: `${this.$apis.orders}/${this.orderId}/unlink_jobs`,
        data: jobIdArray
      }).then(res => {
        if (res?.code === 1000) {
          this.$message.success('Jobs successfully unlinked')
          this.handleLinkJobsDialogClose()
        }
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.el-dialog-job {
  .div-main-list-page-table {
    height: calc(100% - 40px); text-align: right;
    }
  .el-dialog__footer {
    padding: 10px 10px 0 0;
  }
  .div-job {
    height: 54vh;
  }
}

/deep/.el-dialog__body{
  padding-top: 0;
}
</style>
